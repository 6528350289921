import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Siden blev ikke fundet" />
    <section className="fourofour">
      <h1>Siden blev desværre ikke fundet</h1>
      <p>Du har ramt en side, som der ikke eksisterer... </p>
      <AniLink to="/" cover bg="#69b56b" className="buttonTemp">
        Klik her, for at komme tilbage til forsiden
      </AniLink>
    </section>
  </Layout>
)

export default NotFoundPage
